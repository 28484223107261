import { isValidElement, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl'
import { uniq } from 'lodash'

type TitleLevel = 'page' | 'subtitle'

export type PageTitleProps = {
  /** Page title(s) */
  title?: string | JSX.Element | (string | JSX.Element | undefined)[]
  /** Route/title level [default: `page`] */
  level?: TitleLevel
  /** Callback when the title is updated. Useful for testing. */
  onUpdate?: () => void
}

/** App page title separator */
export const TITLE_SEPARATOR = ' | '

/** Vantage app title */
export const APP_TITLE = 'Vantage'

/** Parse a title string or FormattedMessage */
const parseTitle =
  (intl: IntlShape) =>
  (title: string | JSX.Element): string =>
    isValidElement<MessageDescriptor>(title) ? intl.formatMessage(title.props) : (title as string)

const getNewTitles = (intl: IntlShape, titles: PageTitleProps['title']): string => {
  const parseIntlTitle = parseTitle(intl)
  const derivedTitles = Array.isArray(titles) ? titles : [titles]
  return uniq(derivedTitles)
    .map((title) => parseIntlTitle(title as string))
    .filter(Boolean)
    .join(TITLE_SEPARATOR)
}

/**
 * Prepends the provided title(s) to `document.title`.
 *
 * Replaces the current title with the provided title(s) at the specified
 * `level`.
 *
 * - If `level` is `page`, the provided titles are appended to the root title.
 * - If `level` is `subtitle`, the provided titles are appended to the page title.
 *
 * When multiple titles are provided, they are concatenated with the title separator.
 *
 * @example
 * ```tsx
 * <PageTitle title="My App"  /> // "My App"
 * <PageTitle title="My Page" /> // "My Page | My App"
 * <PageTitle title="My Subpage" level="subtitle" /> // "My Subpage | My Page | My App"
 * <PageTitle title={['My Subpage', 'My Page']} /> // "My Subpage | My Page | My App"
 * ```
 *
 */
export function PageTitle({ title, level = 'page', onUpdate }: PageTitleProps) {
  const intl = useIntl()

  const pageTitle = useMemo(() => {
    const newTitles = getNewTitles(intl, title)

    if (newTitles.length > 0 && document.title.startsWith(newTitles)) {
      return document.title
    }

    // For subpage titles, we simply append the new titles to the current title for convenience
    if (level === 'subtitle') {
      const [, pageTitle] = document.title.split(TITLE_SEPARATOR).reverse()
      return [newTitles, pageTitle, APP_TITLE].filter(Boolean).join(TITLE_SEPARATOR)
    }

    return [newTitles, APP_TITLE].filter(Boolean).join(TITLE_SEPARATOR)
  }, [intl, level, title])

  return (
    <Helmet onChangeClientState={() => onUpdate?.()}>
      <title>{pageTitle}</title>
    </Helmet>
  )
}
