import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Configuration {
  publicPath?: string
  csrfToken?: string
}

const initialState: Configuration = {
  publicPath: '/',
  csrfToken: '',
}

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    updateConfig(state, action: PayloadAction<Configuration>) {
      return { ...state, ...action.payload }
    },
  },
})

export const { updateConfig } = configurationSlice.actions
