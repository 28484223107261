import { createContext, PropsWithChildren, useEffect, useMemo } from 'react'
import { createIntl, createIntlCache, injectIntl, IntlProvider, WrappedComponentProps } from 'react-intl'
import { flatten } from 'flat'
import { isNil } from 'lodash'
import { LocaleObject, printValue, setLocale, ValidationError } from 'yup'

import formMessages from '../i18n/locales/default.json'

export type YupErrorMapProviderProps = PropsWithChildren<Partial<WrappedComponentProps>>

const YupErrorMapContext = createContext<YupErrorMapProviderProps>({})

const flatFormMessages: Record<string, string> = flatten(formMessages)

const intlCache = createIntlCache()

/**
 * Raw provider that sets the Yup locale via FormatJS.
 *
 * Returns a nested `IntlProvider` with the combined messages from the
 * parent `IntlProvider` as well as the form messages.
 *
 * @see {@link https://github.com/formatjs/formatjs/issues/1109}
 */
const YupErrorMapProviderRaw = ({ intl, children }: YupErrorMapProviderProps) => {
  // Merge the messages from the parent IntlProvider with the form messages.
  const combinedMessages: Record<string, string> = useMemo(
    () => flatten({ ...flatFormMessages, ...intl?.messages }),
    [intl?.messages]
  )

  const { formatMessage } = useMemo(
    () => createIntl({ locale: intl?.locale ?? 'en', messages: combinedMessages }, intlCache),
    [intl?.locale, combinedMessages]
  )

  useEffect(() => {
    const mixed: Required<LocaleObject['mixed']> = {
      default: ({ path }) => formatMessage({ id: 'yup_errors.mixed.default' }, { path }),
      required: ({ path }) => formatMessage({ id: 'yup_errors.mixed.required' }, { path }),
      defined: ({ path }) => formatMessage({ id: 'yup_errors.mixed.defined' }, { path }),
      notNull: ({ path, values }) => formatMessage({ id: 'yup_errors.mixed.not_null' }, { path, values }),
      oneOf: ({ path, values }) => formatMessage({ id: 'yup_errors.mixed.one_of' }, { path, values }),
      notOneOf: ({ path, values }) => formatMessage({ id: 'yup_errors.mixed.not_one_of' }, { path, values }),
      notType: ({ path, type, value, originalValue }) => {
        const castMessage =
          !isNil(originalValue) && originalValue !== value
            ? ` ${formatMessage(
                { id: 'yup_errors.mixed.not_type_cast_from' },
                { value: printValue(originalValue, true) }
              )}`
            : ''

        return type !== 'mixed'
          ? formatMessage(
              { id: 'yup_errors.mixed.not_type_not_mixed' },
              { path, type, value: printValue(value, true) }
            ) + castMessage
          : formatMessage({ id: 'yup_errors.mixed.not_type_mixed' }, { path, value: printValue(value, true) }) +
              castMessage
      },
    }

    const string: Required<LocaleObject['string']> = {
      length: ({ path, length }) => formatMessage({ id: 'yup_errors.string.length' }, { path, length }),
      min: ({ path, min }) => formatMessage({ id: 'yup_errors.string.min' }, { path, min }),
      max: ({ path, max }) => formatMessage({ id: 'yup_errors.string.max' }, { path, max }),
      matches: ({ path, regex }) => formatMessage({ id: 'yup_errors.string.matches' }, { path, regex: String(regex) }),
      email: ({ path }) => formatMessage({ id: 'yup_errors.string.email' }, { path }),
      url: ({ path }) => formatMessage({ id: 'yup_errors.string.url' }, { path }),
      uuid: ({ path }) => formatMessage({ id: 'yup_errors.string.uuid' }, { path }),
      datetime: ({ path }) => formatMessage({ id: 'yup_errors.string.datetime' }, { path }),
      datetime_precision: ({ path, precision }) =>
        formatMessage({ id: 'yup_errors.string.datetime_precision' }, { path, precision }),
      datetime_offset: ({ path }) => formatMessage({ id: 'yup_errors.string.datetime_offset' }, { path }),
      trim: ({ path }) => formatMessage({ id: 'yup_errors.string.trim' }, { path }),
      lowercase: ({ path }) => formatMessage({ id: 'yup_errors.string.lowercase' }, { path }),
      uppercase: ({ path }) => formatMessage({ id: 'yup_errors.string.uppercase' }, { path }),
    }

    const number: Required<LocaleObject['number']> = {
      min: ({ path, min }) => formatMessage({ id: 'yup_errors.number.min' }, { path, min }),
      max: ({ path, max }) => formatMessage({ id: 'yup_errors.number.max' }, { path, max }),
      lessThan: ({ path, less }) => formatMessage({ id: 'yup_errors.number.less_than' }, { path, less }),
      moreThan: ({ path, more }) => formatMessage({ id: 'yup_errors.number.more_than' }, { path, more }),
      positive: ({ path }) => formatMessage({ id: 'yup_errors.number.positive' }, { path }),
      negative: ({ path }) => formatMessage({ id: 'yup_errors.number.negative' }, { path }),
      integer: ({ path }) => formatMessage({ id: 'yup_errors.number.integer' }, { path }),
    }

    const date: Required<LocaleObject['date']> = {
      min: ({ path, min }) => formatMessage({ id: 'yup_errors.date.min' }, { path, min }),
      max: ({ path, max }) => formatMessage({ id: 'yup_errors.date.max' }, { path, max }),
    }

    const boolean: Required<LocaleObject['boolean']> = {
      isValue: ({ path }) => formatMessage({ id: 'yup_errors.boolean.is_value' }, { path }),
    }

    const object: Required<LocaleObject['object']> = {
      noUnknown: ({ path }) => formatMessage({ id: 'yup_errors.object.no_unknown' }, { path }),
    }

    const array: Required<LocaleObject['array']> = {
      min: ({ path, min }) => formatMessage({ id: 'yup_errors.array.min' }, { path, min }),
      max: ({ path, max }) => formatMessage({ id: 'yup_errors.array.max' }, { path, max }),
      length: ({ path, length }) => formatMessage({ id: 'yup_errors.array.length' }, { path, length }),
    }

    const tuple: Required<LocaleObject['tuple']> = {
      notType: (params) => {
        const { path, value, spec } = params
        const typeLength = spec.types.length

        if (Array.isArray(value)) {
          if (value.length < typeLength)
            return formatMessage(
              { id: 'yup_errors.tuple.not_type_min' },
              { path, min: typeLength, length: value.length, value: printValue(value, true) }
            )
          if (value.length > typeLength)
            return formatMessage(
              { id: 'yup_errors.tuple.not_type_max' },
              { path, max: typeLength, length: value.length, value: printValue(value, true) }
            )
        }

        return ValidationError.formatError(mixed.notType, params)
      },
    }

    const locale: Required<LocaleObject> = {
      mixed,
      string,
      number,
      date,
      object,
      array,
      boolean,
      tuple,
    }

    setLocale(locale)
  }, [formatMessage])

  return (
    <IntlProvider locale={intl?.locale ?? 'en'} messages={combinedMessages}>
      <YupErrorMapContext.Provider value={{ intl }}>{children}</YupErrorMapContext.Provider>
    </IntlProvider>
  )
}

/**
 * Provider that sets the Yup locale via FormatJS.
 *
 * !!!: Relies on `IntlProvider` from `react-intl` being present in the component tree.
 */
export const YupErrorMapProvider = injectIntl(YupErrorMapProviderRaw)
