/**
 * Vantage API v1 base URL
 *
 * TODO: In future, we want to handle this via environment variable.
 */
export const API_V1_BASE_URL = `${window.location.origin}/api`

/**
 * Vantage API v2 base URL
 *
 * TODO: In future, we want to handle this via environment variable.
 */
export const API_V2_BASE_URL = `${window.location.origin}/api/v2`
