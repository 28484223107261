import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { BaseQueryApi, BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query'

import { API_V2_BASE_URL } from '../config/env'
import { withCSRFHeader } from '../utils/auth'

export const v2RootQuery: BaseQueryFn = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: object) => {
  const baseUrl = `${API_V2_BASE_URL}/`

  const addHeaders = (
    headers: Headers,
    api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
  ) => {
    const _headers = withCSRFHeader(headers)

    if (!headers.has('Content-Type')) {
      headers.set('Content-Type', 'application/json')
    }

    return _headers
  }

  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    prepareHeaders: addHeaders,
  })
  return rawBaseQuery(args, api, extraOptions)
}
