import { flatten } from 'flat'

import formMessages from '../../i18n/locales/default.json'

type FlatRecordKeys<T extends Record<string, unknown>, Key = keyof T> = Key extends string
  ? T[Key] extends Record<string, unknown>
    ? `${Key}.${FlatRecordKeys<T[Key]>}`
    : `${Key}`
  : never

export type FlatFormMessageKeys = FlatRecordKeys<typeof formMessages>

const flattenedMessages: FlatFormMessageKeys = flatten(formMessages)

/**
 * Derive an expected message from the Yup error message key.
 *
 * @param key Dot notation key for the message in the formMessages object.
 * @returns A "good enough" error message that replaces placeholders with
 * regular expression wildcards.
 */
export const getYupErrorMessage = (key: FlatFormMessageKeys) => {
  // @ts-expect-error: TypeScript is concerned about possible `number`s, but we'll only get `string`s.
  const message = flattenedMessages[key]
  return new RegExp(message.replace(/{.*?}/g, '.*'))
}
