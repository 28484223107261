import Cookie from 'js-cookie'

/**
 * Gets the CSRF token from the cookies
 */
const getCsrfToken = () => {
  try {
    return Cookie.get('csrftoken') ?? ''
  } catch (error) {
    return ''
  }
}

/**
 * Adds the CSRF token to the headers
 */
export const withCSRFHeader = (headers: Headers) => {
  const csrfToken = getCsrfToken()
  headers.set('X-CSRFToken', csrfToken)
  return headers
}
