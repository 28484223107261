import { createApi } from '@reduxjs/toolkit/query/react'

import { emptyUser, setUser } from '../store/userSlice'
import { ChangePasswordPayload } from '../types/ChangePasswordPayload'
import { PersonalDetailsPayload } from '../types/PersonalDetailsPayload'
import { SystemMessage } from '../types/SystemMessage'
import { VantageUser } from '../types/VantageUser'

import { v2RootQuery } from './v2BaseQuery'

export const coreApi = createApi({
  reducerPath: 'core',
  baseQuery: v2RootQuery,
  tagTypes: ['Messages', 'User'],
  endpoints: (builder) => ({
    getMessages: builder.query<SystemMessage[], number | null>({
      query: (store_id) => ({
        url: '/messages/'.concat(store_id !== null ? store_id.toString().concat('/') : ''),
        method: 'GET',
      }),
    }),
    getUser: builder.query<VantageUser, unknown>({
      query: () => ({
        url: '/user/',
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }): Promise<void> {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
        } catch (err) {
          dispatch(setUser(emptyUser))
        }
      },
      providesTags: ['User'],
    }),
    setActiveRole: builder.mutation<VantageUser, string>({
      query: (role) => ({
        url: '/user/set-active-role/',
        method: 'POST',
        body: JSON.stringify({ role }),
      }),
      invalidatesTags: ['User', 'Messages'],
    }),
    acceptTerms: builder.mutation<string, unknown>({
      query: () => ({
        url: '/user/accept-terms/',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['User'],
    }),
    updatePersonalDetails: builder.mutation<PersonalDetailsPayload, unknown>({
      query: (personalDetails) => ({
        url: `/user/`,
        method: 'PATCH',
        body: JSON.stringify(personalDetails),
      }),
    }),
    updatePassword: builder.mutation<ChangePasswordPayload, unknown>({
      query: (passwordData) => ({
        url: `/user/password`,
        method: 'PUT',
        body: JSON.stringify(passwordData),
      }),
    }),
  }),
})

export const {
  useGetMessagesQuery,
  useGetUserQuery,
  useUpdatePersonalDetailsMutation,
  useUpdatePasswordMutation,
  useSetActiveRoleMutation,
  useAcceptTermsMutation,
} = coreApi
