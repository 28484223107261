import { Theme } from '@mui/material'
import { ComponentsOverrides, Palette, PaletteColor } from '@mui/material/styles'
import { alpha } from '@mui/system/colorManipulator'
import { merge } from 'lodash'

/**
 * Creates focus outline style overrides for each color variant for the
 * provided variant types in the theme.
 * @param theme MUI theme (with defined palette)
 * @returns Focus outline style overrides for each color variant
 */
export const createFocusOutlineStyleOverrides = () => {
  const outlineColor = (theme: Theme, color: string) => (theme.palette[color as keyof Palette] as PaletteColor).main

  const setOutline =
    (color: string) =>
    ({ theme }: { theme: Theme }) => ({
      '&:focus': {
        outline: `2px solid ${outlineColor(theme, color)}`,
      },
    })

  return {
    root: {
      '&:focus': {
        outlineOffset: '-2px',
      },
    },

    contained: ({ theme }) => ({
      '&:focus': {
        outline: `2px solid ${alpha(theme.palette.common.white, 0.5)}`,
      },
    }),

    outlinedError: setOutline('error'),
    outlinedInfo: setOutline('info'),
    outlinedPrimary: setOutline('primary'),
    outlinedSecondary: setOutline('secondary'),
    outlinedSuccess: setOutline('success'),
    outlinedWarning: setOutline('warning'),
    textError: setOutline('error'),
    textInfo: setOutline('info'),
    textPrimary: setOutline('primary'),
    textSecondary: setOutline('secondary'),
    textSuccess: setOutline('success'),
    textWarning: setOutline('warning'),
  } as ComponentsOverrides<Theme>['MuiButton']
}

export const withFocusOutlineStyleOverrides = (
  overrides: ComponentsOverrides<Theme>['MuiButton']
): ComponentsOverrides<Theme>['MuiButton'] => merge(overrides, createFocusOutlineStyleOverrides())
