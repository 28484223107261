import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { API_V1_BASE_URL } from '../config/env'
import { withCSRFHeader } from '../utils/auth'

/**
 * Base RTK Query function for Vantage's V1 API
 *
 * Injects CSRF token into headers.
 */
export const v1BaseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: API_V1_BASE_URL,
  credentials: 'include',
  prepareHeaders: withCSRFHeader,
})
