import { lazy } from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, RouteObject } from 'react-router-dom'

import { PageTitle } from '@lib/ui-shared'

const ForgotPasswordKey = lazy(() => import('./ForgotPasswordKeyPage'))
const SignIn = lazy(() => import('./SignInPage'))
const ForgotPasswordPage = lazy(() => import('./ForgotPasswordPage'))
const SignUpPage = lazy(() => import('./SignUpPage'))

export const routes: RouteObject[] = [
  {
    path: '/auth/*',
    children: [
      {
        path: 'login',
        element: (
          <>
            <PageTitle title={<FormattedMessage id="signin.title" />} />
            <SignIn />
          </>
        ),
      },
      {
        path: 'forgot-password/:resetKey',
        element: (
          <>
            <PageTitle title={<FormattedMessage id="forgot_password.title" />} />
            <ForgotPasswordKey />
          </>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <>
            <PageTitle title={<FormattedMessage id="forgot_password.title" />} />
            <ForgotPasswordPage />
          </>
        ),
      },
      {
        path: 'signup',
        element: (
          <>
            <PageTitle title={<FormattedMessage id="signup.title" />} />
            <SignUpPage />
          </>
        ),
      },
      {
        index: true,
        element: <Navigate to={'login'} />,
      },
    ],
  },

  { path: '*', element: <Navigate to={'/auth/login'} /> },
]
