import React, { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useCookie } from 'react-use'
import { LinearProgress } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import flatten from 'flat'

import { YupErrorMapProvider } from '@lib/forms'
import { config } from '@lib/ui-config'
import { vantageTheme } from '@lib/ui-shared'

import * as defaultMessages from '../i18n/locales/default.json'
import { Configuration, updateConfig } from '../redux/slices/configurationSlice'
import { setupStore } from '../redux/store'
import { routes } from '../routes'

const copy: any = {
  en: flatten(defaultMessages),
}

declare global {
  interface Window {
    config: any
  }
}

if (!window.config) {
  window.config = {
    ...config,
  }
}

export type AppProps = {
  publicPath: string | undefined
}

export function App({ publicPath }: AppProps) {
  const [csrfToken] = useCookie('csrftoken')
  const configurationType: Configuration = {
    publicPath,
    csrfToken: csrfToken || '',
  }

  const store = setupStore()
  store.dispatch(updateConfig(configurationType))

  const router = createBrowserRouter(routes)

  return (
    <HelmetProvider>
      <IntlProvider locale="en" messages={copy['en']}>
        <YupErrorMapProvider>
          <ThemeProvider theme={vantageTheme}>
            <Provider store={store}>
              <Suspense fallback={<LinearProgress />}>
                <RouterProvider router={router} />
              </Suspense>
            </Provider>
          </ThemeProvider>
        </YupErrorMapProvider>
      </IntlProvider>
    </HelmetProvider>
  )
}

export default App
