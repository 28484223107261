import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { authApi } from './apis/authApi'
import { configurationSlice } from './slices/configurationSlice'

// Create the root reducer independently to obtain the RootState type
const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  configuration: configurationSlice.reducer,
})

/**
 * Setup the RTK store with the root reducer and middleware
 *
 * @param preloadedState State to pre-load the store with
 */
export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
