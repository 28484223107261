export const config = {
  inboundUrl: '/',
  demoUrl: 'http://localhost:3001/api',
  publicPath: 'local-path/',
  frontChatId: 'bd42f3b5952f8824fe3349e195c89231',
  co_brand: 'hdca',
  stripeKey: 'pk_test_LNb9XFqQvFP2kzhzEW42zXXX',
}

export type ConfigurationType = {
  frontChatId: string
  sentryDSN: string
  inboundUrl: string
  demoUrl: string
  demoDdKey: string
  publicPath: string
  segmentWriteKey: string
  muiKey: string
  stripeKey: string
  csrfToken: string
}

export const mapConfigurationTo = (config: any): ConfigurationType => {
  const context = {
    inboundUrl: config.inboundUrl,
    frontChatId: config.frontChatId,
    publicPath: config.publicPath,
    demoUrl: config.demoUrl,
    demoDdKey: config.demoDdKey,
    sentryDSN: config.sentryDSN,
    muiKey: config.muiKey,
    stripeKey: config.stripeKey,
    segmentWriteKey: config.segmentWriteKey,
  } as ConfigurationType

  return context
}
