import React, { FC, HTMLAttributes, ImgHTMLAttributes, ReactHTML, RefAttributes, RefObject } from 'react'
import { useSelector } from 'react-redux'

export const withPublicPath = <
  T extends keyof ReactHTML,
  K extends HTMLAttributes<HTMLElement> & RefAttributes<HTMLElement>
>(
  Component: T,
  ref?: RefObject<HTMLElement>
): FC<K> => {
  return (props) => {
    const isImage = (props: unknown): props is ImgHTMLAttributes<HTMLImageElement> => {
      const cast = props as ImgHTMLAttributes<HTMLImageElement>
      return cast.src !== undefined || cast.srcSet !== undefined
    }

    const { publicPath } = useSelector((state: any) => state.configuration)

    if (isImage(props) && publicPath) {
      const { src, srcSet, ...remainingProps } = props as ImgHTMLAttributes<HTMLImageElement>

      const baseUrlSrc = src ? `${publicPath}${src}` : undefined
      const baseUrlSrcSet = srcSet ? `${publicPath}${srcSet}` : undefined

      return React.createElement(Component, {
        ...remainingProps,
        src: baseUrlSrc,
        srcSet: baseUrlSrcSet,
        ref,
      })
    }
    return React.createElement(Component, props)
  }
}
